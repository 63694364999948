import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"

const DeletePolicy = () => {

    const [pageTile, setPageTitle] = useState("Delete Policy")
    
    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
        <h1>Delete Account</h1>
<p>Last updated: January 19, 2024</p>
<p>This Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>Kindly note that accounts that are inactive for at least 90 days will be automatically deleted from our database</p>
<p>Deletion includes name, email, and other information associated with the user on our platform if inactive for 90 days.</p>
        </div> 
    )
}

export default DeletePolicy;