import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"
import truncate from "../../utilities/truncateString"
import Loading from "../../utilities/Loading"

const ZonesGroups = () => {

    const [pageTile, setPageTitle] = useState("Zones/Groups")
    const [isLoading, setLoading] = useState(false)
    const [zones, setZones] = useState([])
    const [zoneCode, setZoneCode] = useState([])
    const [zoneGroup, setZoneGroup] = useState(null)
      
    const getZones = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/zone_groups', true);
        if(x != null && x.status === 1){
            setZones(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const deleteBtn = async (id) => {
        let canDelete = window.confirm("Do you want to delete zone/group?")
        if(canDelete){

        setLoading(true)
        let x = await requestHandler.get('admin/zone_groups/'+id+'/delete', true);
        if(x != null && x.status === 1){
            toast.success(x.message)
            getZones()
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
    }

      }

      const createZoneGroup = async () =>{
        //alert(duration)
        let url = 'admin/zone_group/add';
        setLoading(true)
        let x = await requestHandler.post(url, {zone_group: zoneGroup, code: zoneCode, status: 1}, false, true)
            if(x != null && x.status === 1)
            {

                toast.success(x.message)
                getZones()
            }
            else if(x != null && x.status === 0)
            {
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    useEffect(() => {
        document.title = pageTile
        getZones()
    },[pageTile])

    return(
        <div className="col">
                <div class="col-12 col-xxl-8 mb-5">
          <h2 class="small-title">{pageTile}</h2>
          <div class="card mb-3">
          <div class="card-body">
                <div class="mb-2 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Code</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setZoneCode(e.target.value)} value={zoneCode} />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Zone/Group</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setZoneGroup(e.target.value)} value={zoneGroup} />
                      </div>
                    </div>

                    <div class="mb-2 row">
                      <div class="col-sm-8 col-md-9 col-lg-10 ms-auto">
                      {isLoading? <Loading/> : 
                        <button onClick={createZoneGroup} type="button" class="btn btn-primary">Create New</button>
                       }                        
                      </div>
                    </div>
                    </div>
                    </div>

          <div class="row g-2">
            
          {zones.length > 0? zones.map(x => <div class="col-12 col-lg-12">
              <div class="card">
                  <div class="col m-1">
                  <div class="card-body d-flex flex-row pt-0 pb-0 ps-3 pe-0 h-100 align-items-center justify-content-between">
                      <div class="d-flex flex-column">
                      <div class="text-normal text-muted">{x.code}</div>
                        <div style={{whiteSpace: "pre-wrap"}}>
                        <div class="text-normal text-muted">{x.zone_group}</div>
                      </div>
                      </div>
                      
                      <div style={{zIndex: 100}} class="d-flex">
                    <i onClick={() => deleteBtn(x.id)} className="fa-regular fa-times fa-2x text-danger"></i>
                    </div>
                    </div>

                  </div>
                  

              </div>
            </div>
            ) : '' }

          </div>
        </div>
        </div>  
    )
}

export default ZonesGroups;