import React, { useEffect, useRef, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import countriesAndDialCodes from "../../utilities/countries"
import Images from "../../utilities/images"
import { ToastContainer, toast } from "react-toastify"
import Loading from "../../utilities/Loading"
import urls from "../../utilities/urls"

const UpdateProfile = () => {
    let token = localStorage.getItem('access_token')
    const inputRef = useRef(null);
    const [pageTile, setPageTitle] = useState("Update Profile")
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [gender, setGender] = useState(null) 
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [dob, setDob] = useState(null)
    const [zone, setZone] = useState(null)
    const [country, setCountry] = useState(null)
    const [countries, setCountries] = useState(countriesAndDialCodes)
    const [picture, setPicture] = useState(null)
    const [userData, setUserData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [zones, setZones] = useState([])

    const getZones = async () => {
      setLoading(true)
      let x = await requestHandler.get('admin/zone_groups', false);
      if(x != null && x.status === 1){
          setZones(x.message)
          //console.log(x.message)
      }
      else{
          //toast.error(x.message)
      }
      setLoading(false)
    }

    //const [img, setImg] = useState('');

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    const uploadImage = (img) => {
        setLoading(true)
      const formData = new FormData();
//      formData.append('name', fileX.name);
      formData.append('file', img, img.name);
    console.log(formData)

        const postOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            contentType: false,
            processData: false,
            body: formData
        };

        fetch(urls.apiurl + 'user/profile/update/picture', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                    toast.success(json.message)
                    setLoading(false)
                    //window.location.href = '/user/profile/update'
                }
                else {
                    toast.error(json.message)
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }

    const handleFileInput = (e) => {
        setLoading(true)
        if(e) {
        setPicture(URL.createObjectURL(e[0]));

        //handle file upload
      uploadImage(e[0]);

        console.log('files: ', e);
        }
      
      };

    const userProfile = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            //setUserData(y)
            setEmail(y.email)
            setPhone(y.phone)
            setFirstName(y.first_name)
            setLastName(y.last_name)
            setCountry(y.country)
            setPicture(y.picture)
            setDob(y.dob)
            setZone(y.zone_group)
            setGender(y.gender)
        }
      }


    const updateProfile = async () =>{
        let url = 'user/profile/update';
        if(email === null){
            url = 'user/profile/create';
        }
        setLoading(true)
        let x = await requestHandler.post(url, {email: email, phone: phone, first_name: firstName, last_name: lastName, gender: gender, country: country, dob: dob, zone_group: zone}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }
    
    useEffect(() => {
      checkIfLoggedIn()
        document.title = pageTile
        userProfile()
        getZones()
    },[pageTile])

    return(
        <div className="col">
        <h2 class="small-title">Profile Update</h2>
              <div class="card mb-5">
                <div class="card-body">
                  <form>
                  <div class="mb-3 row">
                  <div className="d-flex justify-content-center">
                    <div class="sw-13 position-relative mb-3">
                        <img src={picture !== null? picture :  Images().profileImage} class="img-fluid rounded-xl" alt="thumb" />
                        {/* <button className="btn-primary btn-sm" onClick={handleUploadClick}>
                            Edit Photo
                        </button> */}
                        </div>
                      </div>
                      
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Update Picture</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        {isLoading? <Loading/> : 
                        <input className="form-control" accept="image/*" style={{display: 'flex'}} ref={inputRef} type="file" onChange={e => handleFileInput(e.target.files)} />
                        }  
                      
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">First Name</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setFirstName(e.target.value)} value={firstName} />
                      </div>
                    </div>                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Last Name</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setLastName(e.target.value)} value={lastName} />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Gender</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <select onChange={e => setGender(e.target.value)} class="form-control" data-width="100%" id="genderSelect">
                          <option value={''}>Select</option>
                          <option value={'Male'}>{'Male'}</option>
                          <option value={'Female'}>{'Female'}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Country</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="genderSelect">
                          <option value={country}>{country}</option>
                          {
                            countries.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                        }
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Phone</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="tel" class="form-control" onChange={e => setPhone(e.target.value)} value={phone} />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Birthday</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="date" class="form-control" onChange={e => setDob(e.target.value)} value={dob} />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Zone/Group</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <select onChange={e => setZone(e.target.value)} class="form-control" data-width="100%" id="zone">
                          <option value={zone}>{zone}</option>
                          {
                            zones.map((x) => {
                            return (<option value={x.zone_group}>{x.zone_group}</option>)  
                            })
                        }
                        </select>
                      </div> 
                    </div>
                    <div class="mb-3 row mt-5">
                      <div class="col-sm-8 col-md-9 col-lg-10 ms-auto">
                      {isLoading? <Loading/> : 
                        <button onClick={updateProfile} type="button" class="btn btn-primary">Update</button>
                        }                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
        </div>  
    )
}

export default UpdateProfile;