import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading";
import { Link, useParams } from "react-router-dom";
import requestHandler from "../../utilities/requestHandler";
import bgImage from "../../utilities/images";
import Images from "../../utilities/images";


const Register = () => {
    
    const params = new URLSearchParams(window.location.search)
    const {referer} = useParams()
    const [referral, setReferral] = useState(referer === undefined? null : referer);
    const [pageTile, setPageTitle] = useState("Registeration")
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [dob, setDob] = useState(null)
    const [zone, setZone] = useState(null)
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState(params.get('email'));
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [isLoading, setLoading] = useState(false)
    const [emailAvailable, setEmailAvailable] = useState(false)
    const [zones, setZones] = useState([])

    const getZones = async () => {
      setLoading(true)
      let x = await requestHandler.get('admin/zone_groups', false);
      if(x != null && x.status === 1){
          setZones(x.message)
          //console.log(x.message)
      }
      else{
          //toast.error(x.message)
      }
      setLoading(false)
    }

    const clearFileds = () => {
        //setEmail('')
        setUsername('')
        setPassword('')
        setPasswordConfirmation('')
            }

    useEffect(() => {
      getZones()
        document.title = pageTile
    },[pageTile])

    const loginAction = async () => {
        setLoading(true)
        let Data = {email: email, password: password}
        let x = await requestHandler.post('auth/login', Data, false, false)
        if(x != null && x.status === 1){
            //let y = x.message;
            toast.success(x.message)
            //set login token
            localStorage.setItem('access_token', x.access_token)
            localStorage.setItem('account_type', x.account_type)
            window.location.href = "/"
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else{
            toast.error('Something went wrong')
        }
        setLoading(false)
    }


    const regAction = async () => {
        setLoading(true)
        //let Data = {username: username, email: email, referral: referral, password: password, password_confirmation: password_confirmation}
        let Data = {first_name: firstName, last_name: lastName, email: email, dob: dob, zone_group: zone, referral: referral, password: password, password_confirmation: password_confirmation}
        let x = await requestHandler.post('auth/register', Data, false, false)
        if(x != null && x.status === 1){
            //let y = x.message;
            toast.success(x.message)
            loginAction()
        }
        else if(x != null && x.status === 0)
        {
            toast.error(x.message)
        }
        else
        {
            toast.error('Something went wrong')
        }
        setLoading(false)
    }
    
    return(
        <div class="col">

<div class="row justify-content-center align-items-center">
            <div className="col-md-6">
                <section className="scroll-section" id="login">
                  <form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <label>First Name</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="user"></i>
                        <input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" placeholder="First Name" />
                      </div>
                      <label>Last Name</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="user"></i>
                        <input value={lastName} onChange={e => setLastName(e.target.value)} className="form-control" placeholder="Last Name" />
                      </div>
                      <label>Email</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="email"></i>
                        <input value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="" />
                      </div>
                      <label>Date of Birth</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="calendar"></i>
                        <input type="date" value={dob} onChange={e => setDob(e.target.value)} className="form-control" placeholder="" />
                      </div>
                      <label>Password</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password} onChange={e => setPassword(e.target.value)} className="form-control" type="password" placeholder="" />
                      </div>
                      <label>Confirm Password</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password_confirmation} onChange={e => setPasswordConfirmation(e.target.value)} className="form-control" type="password" placeholder="" />
                      </div>
                      <label>Zone/Group</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="building"></i>
                        <select onChange={e => setZone(e.target.value)} class="form-control" data-width="100%" id="zone">
                          <option value={zone}>{zone}</option>
                          {
                            zones.map((x) => {
                            return (<option value={x.zone_group}>{x.zone_group}</option>)  
                            })
                        }
                        </select>
                      </div>
                      <label>Referral ID</label>
                      <div className="mb-3 filled">
                        <i data-cs-icon="user"></i>
{referer === undefined?  <input value={referral} onChange={e => setReferral(e.target.value)} className="form-control" type="text" placeholder="" />
:
<input value={referral} onChange={e => setReferral(e.target.value)} className="form-control" type="text" placeholder="" readOnly />
}
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                    <Link to="/auth/login" className="me-3">Log In</Link>
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={regAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Register</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>
                </section>
                </div>

                <div class="col-md-6 col-lg-6 col-xl-6 d-none d-lg-block">  
                          <img src={Images().landingImage}
                    class="img-fluid" alt="Sample image" />
                          </div>

                </div>

                <ToastContainer />
        </div>
    )
}

export default Register;